import Vue from "vue";
import App from "pages/App.vue";
import router from "./router";
import store from "@/store";

// 初始化 css
import "normalize.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/common/stylus/index.styl";
// // 权限
import ability from "@/ability";
import { abilitiesPlugin } from "@casl/vue";
// 全局 vue 设置
import ininVueGlobalConfig from "globalConfig";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.use(abilitiesPlugin, ability);
Vue.use(ElementUI);
ininVueGlobalConfig(Vue);

import VueNumber from "vue-number-animation";
Vue.use(VueNumber);
import BeautifulChat from "vue-beautiful-chat";
Vue.use(BeautifulChat);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
