import Vue from "vue";
import Router from "vue-router";
import types from "./router-types";
import { ROUTER_TYPES } from "./router-types";

Vue.use(Router);

const { AI_HOME_PAGE, INTELLIGENT_DIAGNOSIS, VIDEO_COURSE, CREDIT_USAGE } =
  types;
// const WEEKLY_DATA = types.WEEKLY_DATA;
// const INDEX_TASK = types.INDEX_TASK;
// const PEER_ANALYSIS = types.PEER_ANALYSIS;
// const REPORT_CONCLUSION = types.REPORT_CONCLUSION;
// const REPORT = types.REPORT;

const AI_HOME_PAGE_KEY = ROUTER_TYPES.AI_HOME_PAGE;
const INTELLIGENT_DIAGNOSIS_KEY = ROUTER_TYPES.INTELLIGENT_DIAGNOSIS;
const VIDEO_COURSE_KEY = ROUTER_TYPES.VIDEO_COURSE;
const CREDIT_USAGE_KEY = ROUTER_TYPES.CREDIT_USAGE;
// const WEEKLY_DATA_KEY = ROUTER_TYPES.WEEKLY_DATA;
// const INDEX_TASK_KEY = ROUTER_TYPES.INDEX_TASK;
// const ADVERTISING_KEY = ROUTER_TYPES.ADVERTISING;
// const REPORT_CONCLUSION_KEY = ROUTER_TYPES.REPORT_CONCLUSION;
// const REPORT_KEY = ROUTER_TYPES.REPORT;
// const OPERATING_GUIDE_KEY = ROUTER_TYPES.OPERATING_GUIDE;
// const INDUSTRY_HOT_PRODUCTS = types.INDUSTRY_HOT_PRODUCTS;
// const HOT_PRODUCTS_REPORT = types.HOT_PRODUCTS_REPORT;
// const INDUSTRY_HOT_PRODUCTS_KEY = ROUTER_TYPES.INDUSTRY_HOT_PRODUCTS;
// const HOT_PRODUCTS_REPORT_KEY = ROUTER_TYPES.HOT_PRODUCTS_REPORT;

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: `/${AI_HOME_PAGE_KEY}`,
    },
    {
      ...AI_HOME_PAGE,
      meta: {
        title: AI_HOME_PAGE.name,
      },
      component: () =>
        import(
          /* webpackChunkName: "view-[request]" */ `pages/index/view/${AI_HOME_PAGE_KEY}/${AI_HOME_PAGE_KEY}`
        ),
    },
    {
      ...INTELLIGENT_DIAGNOSIS,
      meta: {
        title: INTELLIGENT_DIAGNOSIS.name,
      },
      component: () =>
        import(
          /* webpackChunkName: "view-[request]" */ `pages/index/view/${INTELLIGENT_DIAGNOSIS_KEY}/${INTELLIGENT_DIAGNOSIS_KEY}`
        ),
    },
    {
      ...VIDEO_COURSE,
      meta: {
        title: VIDEO_COURSE.name,
      },
      component: () =>
        import(
          /* webpackChunkName: "view-[request]" */ `pages/index/view/${VIDEO_COURSE_KEY}/${VIDEO_COURSE_KEY}`
        ),
    },
    {
      ...CREDIT_USAGE,
      meta: {
        title: CREDIT_USAGE.name,
      },
      component: () =>
        import(
          /* webpackChunkName: "view-[request]" */ `pages/index/view/${CREDIT_USAGE_KEY}`
        ),
    },
    // {
    //   ...INDEX_TASK,
    //   meta: {
    //     title: INDEX_TASK.name
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "view-[request]" */ `pages/index/view/${INDEX_TASK_KEY}/${INDEX_TASK_KEY}`)
    // },
    // {
    //   ...WEEKLY_DATA,
    //   meta: {
    //     title: WEEKLY_DATA.name,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "view-[request]" */ `pages/index/view/${WEEKLY_DATA_KEY}/${WEEKLY_DATA_KEY}`
    //     ),
    // },
    // {
    //   ...PEER_ANALYSIS,
    //   meta: {
    //     title: PEER_ANALYSIS.name
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "view-[request]" */ `pages/index/view/${ADVERTISING_KEY}/${ADVERTISING_KEY}`)
    // },
    // {
    //   ...REPORT_CONCLUSION,
    //   meta: {
    //     title: REPORT_CONCLUSION.name
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "view-[request]" */ `pages/index/view/${REPORT_CONCLUSION_KEY}/${REPORT_CONCLUSION_KEY}`)
    // },
    // {
    //   ...REPORT,
    //   meta: {
    //     title: REPORT.name,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "view-[request]" */ `pages/index/view/${REPORT_KEY}/${REPORT_KEY}`
    //     ),
    // },
    // {
    //   ...INDUSTRY_HOT_PRODUCTS,
    //   meta: {
    //     title: INDUSTRY_HOT_PRODUCTS.name,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "view-[request]" */ `pages/index/view/${INDUSTRY_HOT_PRODUCTS_KEY}/${INDUSTRY_HOT_PRODUCTS_KEY}`
    //     ),
    // },
    // {
    //   ...HOT_PRODUCTS_REPORT,
    //   meta: {
    //     title: HOT_PRODUCTS_REPORT.name,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "view-[request]" */ `pages/index/view/${HOT_PRODUCTS_REPORT_KEY}/${HOT_PRODUCTS_REPORT_KEY}`
    //     ),
    // },
    // {
    //   ...types.OPERATING_GUIDE,
    //   meta: {
    //     title: types.OPERATING_GUIDE.name
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "view-[request]" */ `pages/index/view/${OPERATING_GUIDE_KEY}/${OPERATING_GUIDE_KEY}`)
    // }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
